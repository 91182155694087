.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.35;
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 20px;
  border-right: 1px solid lightgray;
}
.sidebar__headerAvatar {
  padding: 0 !important;
}
.sidebar__headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10vw;
}
/* .sidebar__headerRight .MuiSvgIcon-root {
  margin-right: 2vw;
} */
.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 39px;
  padding: 10px;
}
.sidebar__searchContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.sidebar__searchContainer > .MuiSvgIcon-root {
  color: #808080;
  padding: 10px;
}

.sidebar__searchContainer > input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}

.sidebar__chats {
  flex: 1;
  background-color: #fff;
  overflow: scroll;
}
