.chat__centerTimestamp {
  position: sticky;
  top: -25px;
  z-index: 1;
  font-size: 16px;
  padding: 5px 12px;
  width: 75px;
  border-radius: 7.5px;
  background-color: #e1f5fe;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65;
  overflow-x: hidden;
}
.chat__header {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 30px;
  border-bottom: 1px solid lightgray;
}
.chat__header > .MuiAvatar-root {
  cursor: pointer;
}
.chat__headerInfo {
  flex: 1;
  padding-left: 20px;
}
.chat__headerInfo > h3 {
  cursor: pointer;
  margin-bottom: 3px;
  font-weight: 500;
}
.chat__headerInfo > p {
  color: #808080;
}
.chat__body {
  flex: 1;
  background-image: url("../bg-chat.png");
  background-color: #e5ddd5;
  background-blend-mode: soft-light;
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  overflow: scroll;
  overflow-x: hidden;
}
.chat__message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.chat__reciever {
  margin-left: auto;
  background-color: #dcf8c6;
}
.chat__message.arrow {
  margin-top: 30px;
  border-radius: 0 10px 10px 10px;
}
.chat__message.arrow.chat__reciever {
  border-radius: 10px 0 10px 10px;
}
.chat__message.arrow::after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 5px solid transparent;
  left: -10px;
  top: 0;
}
.chat__message.arrow.chat__reciever::after {
  border-left: 5px solid #dcf8c6;
  border-top: 5px solid #dcf8c6;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: -10px;
  left: auto;
}
.chat__message.chat__img > img {
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
}
.chat__message.chat__img .chat__timestamp {
  position: absolute;
  bottom: 15px;
  right: 11px;
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
}
.chat__timestamp {
  margin-left: 10px;
  font-size: xx-small;
}
.chat__name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}
.chat__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid #808080;
}
.chat__footer > form {
  flex: 1;
  display: flex;
}
.chat__footer > form > input {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline-width: 0;
}
.chat__footer > form > button {
  display: none;
}
.chat__footer > .MuiSvgIcon-root {
  color: #808080;
  padding: 10px;
}
.emoji-mart-emoji {
  outline-width: 0;
}
