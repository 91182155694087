@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.chat__centerTimestamp {
  position: -webkit-sticky;
  position: sticky;
  top: -25px;
  z-index: 1;
  font-size: 16px;
  padding: 5px 12px;
  width: 75px;
  border-radius: 7.5px;
  background-color: #e1f5fe;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65 1;
  overflow-x: hidden;
}
.chat__header {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 30px;
  border-bottom: 1px solid lightgray;
}
.chat__header > .MuiAvatar-root {
  cursor: pointer;
}
.chat__headerInfo {
  flex: 1 1;
  padding-left: 20px;
}
.chat__headerInfo > h3 {
  cursor: pointer;
  margin-bottom: 3px;
  font-weight: 500;
}
.chat__headerInfo > p {
  color: #808080;
}
.chat__body {
  flex: 1 1;
  background-image: url(/static/media/bg-chat.7fff1c4f.png);
  background-color: #e5ddd5;
  background-blend-mode: soft-light;
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  overflow: scroll;
  overflow-x: hidden;
}
.chat__message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.chat__reciever {
  margin-left: auto;
  background-color: #dcf8c6;
}
.chat__message.arrow {
  margin-top: 30px;
  border-radius: 0 10px 10px 10px;
}
.chat__message.arrow.chat__reciever {
  border-radius: 10px 0 10px 10px;
}
.chat__message.arrow::after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 5px solid transparent;
  left: -10px;
  top: 0;
}
.chat__message.arrow.chat__reciever::after {
  border-left: 5px solid #dcf8c6;
  border-top: 5px solid #dcf8c6;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: -10px;
  left: auto;
}
.chat__message.chat__img > img {
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
}
.chat__message.chat__img .chat__timestamp {
  position: absolute;
  bottom: 15px;
  right: 11px;
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
}
.chat__timestamp {
  margin-left: 10px;
  font-size: xx-small;
}
.chat__name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}
.chat__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid #808080;
}
.chat__footer > form {
  flex: 1 1;
  display: flex;
}
.chat__footer > form > input {
  flex: 1 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline-width: 0;
}
.chat__footer > form > button {
  display: none;
}
.chat__footer > .MuiSvgIcon-root {
  color: #808080;
  padding: 10px;
}
.emoji-mart-emoji {
  outline-width: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.35 1;
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 20px;
  border-right: 1px solid lightgray;
}
.sidebar__headerAvatar {
  padding: 0 !important;
}
.sidebar__headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10vw;
}
/* .sidebar__headerRight .MuiSvgIcon-root {
  margin-right: 2vw;
} */
.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 39px;
  padding: 10px;
}
.sidebar__searchContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.sidebar__searchContainer > .MuiSvgIcon-root {
  color: #808080;
  padding: 10px;
}

.sidebar__searchContainer > input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}

.sidebar__chats {
  flex: 1 1;
  background-color: #fff;
  overflow: scroll;
}

.sidebarChat {
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}
.sidebarChat:hover {
  background-color: #ebebeb;
}
.sidebarChat__info {
  margin-left: 15px;
}
.sidebarChat__info > span > h4 {
  display: inline;
}
.sidebarChat__info > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}
.sidebarChat.active {
  background-color: #ebebeb;
}

.app {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #dadbd3;
}

.app__body {
  display: flex;
  background-color: #ededed;
  height: 100vh;
  width: 100vw;
}

.loader {
  height: 100vh;
  width: 100vw;
  flex: 1 1;
}
.loader > div {
  width: 50%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.MuiLinearProgress-colorPrimary {
  background-color: #2a2f3240 !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #00af9c !important;
}

.profile {
  display: flex;
  flex-direction: column;
  flex: 0.35 1;
}

.profile__header {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 20px;
  background-color: #00bfa5;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.profile__header > button {
  margin-right: 10px;
  color: #fff;
}

.profile__pic {
  height: 258px;
  display: grid;
  place-items: center;
}

.profile__picInner {
  display: flex;
}

.profile__pic .MuiAvatar-root {
  width: 200px;
  height: 200px;
}

.profile__name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  padding: 20px;
  background-color: #fff;
}

.profile__name > span {
  color: #009688;
  font-size: 14px;
}

.profile__name > .name {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile__picOverlay {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #666666cc;
}
.profile__picOverlay .MuiSvgIcon-root {
  font-size: 36px;
  color: #fff;
}
.profile__picInner:hover .profile__picOverlay {
  visibility: visible;
}

.roomInfo {
  display: flex;
  flex-direction: column;
  flex: 0.4 1;
  overflow-x: hidden;
}
.roomInfo__header {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 30px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid lightgray;
}
.roomInfo__header > span {
  margin-left: 10px;
}
.roomInfo__body {
  background-color: #fff;
}
.roomInfo__pic {
  height: 258px;
  display: grid;
  place-items: center;
}

.roomInfo__picInner {
  display: flex;
}

.roomInfo__pic .MuiAvatar-root {
  width: 200px;
  height: 200px;
}
.roomInfo__picOverlay {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #666666cc;
}
.roomInfo__picOverlay .MuiSvgIcon-root {
  font-size: 36px;
  color: #fff;
}
.roomInfo__picInner:hover .roomInfo__picOverlay {
  visibility: visible;
}
.roomInfo__name {
  margin: 0 20px 25px;
  font-size: 20px;
  font-weight: 500;
}

.login {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo__text {
  font-family: "Roboto", cursive;
  color: #5ecb59;
  font-size: 2.75rem;
  margin-top: 20px;
  text-align: center;
}

.login__logo {
  flex: 1 1;
  margin: 100px auto;
}

.google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:active {
  background: #1669f2;
}
.login__buttons {
  display: flex;
  width: 250px;
  align-items: center;
  justify-content: space-evenly;
}

.login__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30vh auto auto;
  padding: 20px;
  width: 40%;
  max-width: 400px;
  border-radius: 10px;
  outline-width: 0;
  background-color: #fff;
}

.login__form > input {
  width: 70%;
  margin: 5px 0;
  height: 30px;
  padding: 1px 10px;
  border-radius: 5px;
  border-width: 1px;
}

* {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

