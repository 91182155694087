@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.login {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo__text {
  font-family: "Roboto", cursive;
  color: #5ecb59;
  font-size: 2.75rem;
  margin-top: 20px;
  text-align: center;
}

.login__logo {
  flex: 1;
  margin: 100px auto;
}

.google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:active {
  background: #1669f2;
}
.login__buttons {
  display: flex;
  width: 250px;
  align-items: center;
  justify-content: space-evenly;
}

.login__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30vh auto auto;
  padding: 20px;
  width: 40%;
  max-width: 400px;
  border-radius: 10px;
  outline-width: 0;
  background-color: #fff;
}

.login__form > input {
  width: 70%;
  margin: 5px 0;
  height: 30px;
  padding: 1px 10px;
  border-radius: 5px;
  border-width: 1px;
}
